import { Grid } from '@mui/material';
import HistoricalData from '#/components/pages/PartnerAdminDashboard/sections/analytics/charts/analytics-historical-data';
import { toRgba } from '#/utils/cssStyles';
import Demographics from '#/components/pages/PartnerAdminDashboard/sections/analytics/charts/analytics-demographics';
import useLocales from '#/hooks/useLocales';
import { fDate } from '#/utils/formatTime';
import { useGetChartTableData } from '#/api/partnerQueries';
import useAuth from '#/hooks/useAuth';

type Props = {
  chartColor: {
    primary_color: string | undefined;
    secondary_color: string | undefined;
  };
};

export default function Charts({ chartColor }: Props) {
  const { user } = useAuth();

  const { translate } = useLocales();

  const enableAPIRequest =
    user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

  const { data } = useGetChartTableData(enableAPIRequest);

  const dateLabels =
    data?.dashboard?.logins?.map((item) => fDate(item.date, undefined, 'en')) ||
    [];

  const logins_created =
    data?.dashboard?.logins?.map((item) => item.logins_created) || [];

  const conversion_rate_to_login =
    data?.dashboard?.logins?.map((item) => item.conversion_rate_to_login) || [];

  const total_visits =
    data?.dashboard?.visits?.map((item) => item.total_visits) || [];

  const subscriptions_created =
    data?.dashboard?.subscriptions?.map((item) => item.subscriptions_created) ||
    [];

  const conversion_rate_to_paying =
    data?.dashboard?.subscriptions?.map(
      (item) => item.conversion_rate_to_paying
    ) || [];

  const ageRanges = Object.keys(data?.dashboard?.gender_age_stats?.M ?? {});

  return (
    <Grid container spacing={1}>
      <Grid xs={12} md={6} lg={8}>
        <HistoricalData
          title={String(translate('adminDashboard.historicalData'))}
          subheader={String(
            translate('adminDashboard.historicalDataSubheader', {
              subscription_growth_percentage:
                data?.dashboard?.subscription_growth_percentage,
            })
          )}
          chart={{
            labels: dateLabels,
            colors: [
              toRgba(chartColor?.primary_color ?? '#3366FF', 1),
              toRgba(chartColor?.primary_color ?? '#3366FF', 0.7),
              toRgba(chartColor?.primary_color ?? '#3366FF', 0.5),
              toRgba(chartColor?.secondary_color ?? '#291B4D', 1),
              toRgba(chartColor?.secondary_color ?? '#291B4D', 0.5),
            ],
            series: [
              {
                name: String(translate('adminDashboard.charts.visitors')),
                type: 'column',
                fill: 'solid',
                data: total_visits,
              },
              {
                name: String(
                  translate('adminDashboard.charts.conversionRateToLogin')
                ),
                type: 'line',
                fill: 'solid',
                data: conversion_rate_to_login,
              },
              {
                name: String(translate('adminDashboard.charts.loginsCreated')),
                type: 'area',
                fill: 'gradient',
                data: logins_created,
              },
              {
                name: String(
                  translate('adminDashboard.charts.conversionRateToPaying')
                ),
                type: 'column',
                fill: 'line',
                data: conversion_rate_to_paying,
              },
              {
                name: String(
                  translate('adminDashboard.charts.subscriptionsCreated')
                ),
                type: 'column',
                fill: 'line',
                data: subscriptions_created,
              },
            ],
          }}
        />
      </Grid>
      <Grid xs={12} md={6} lg={4}>
        <Demographics
          title={String(translate('adminDashboard.demographics'))}
          primaryColor={chartColor?.primary_color}
          secondaryColor={chartColor?.secondary_color}
          chart={{
            categories: ageRanges || [],
            colors: [
              chartColor?.primary_color ?? '#3366FF',
              chartColor?.secondary_color ?? '#291B4D',
            ],
            series: [
              {
                name: String(translate('adminDashboard.women')),
                data: ageRanges?.map(
                  // @ts-ignore
                  (age) => data?.dashboard?.gender_age_stats?.F[age] || 0
                ),
              },
              {
                name: String(translate('adminDashboard.man')),
                data: ageRanges?.map(
                  // @ts-ignore
                  (age) => data?.dashboard?.gender_age_stats?.M[age] || 0
                ),
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}
