interface ServiceCard {
  title: string;
  icon: string;
  description: string;
  url?: string;
  serviceKey?: string;
  progressive?: boolean;
}

const serviceCards: ServiceCard[] = [
  {
    title: 'global.dashCardInfos.livingWill.title',
    icon: '/assets/icons/ic-living-will.svg',
    description: 'global.dashCardInfos.livingWill.description',
    url: '/dashboard/patientenverfuegung',
    serviceKey: 'living_will',
    progressive: true,
  },
  {
    title: 'global.dashCardInfos.powerOfAttorney.title',
    icon: '/assets/icons/ic-power-of-attorney.svg',
    description: 'global.dashCardInfos.powerOfAttorney.description',
    url: '/dashboard/vorsorgeauftrag',
    serviceKey: 'power_of_attorney',
    progressive: true,
  },
  {
    title: 'global.dashCardInfos.lastWill.title',
    icon: '/assets/icons/ic-last-will.svg',
    description: 'global.dashCardInfos.lastWill.description',
    url: '/dashboard/testament',
    serviceKey: 'last_will',
    progressive: true,
  },
  {
    title: 'global.dashCardInfos.marriageContract.title',
    icon: '/assets/icons/ic-marriage-contract.svg',
    description: 'global.dashCardInfos.marriageContract.description',
    url: '/dashboard/ehevertrag',
    serviceKey: 'marriage_contract',
    progressive: true,
  },
  {
    title: 'global.dashCardInfos.assetsRegister.title',
    icon: '/assets/icons/ic-heirs-register.svg',
    description: 'global.dashCardInfos.assetsRegister.description',
    url: '/dashboard/vermoegens-und-sachregister',
    serviceKey: 'register_of_assets',
  },
  {
    title: 'global.dashCardInfos.digitalFootprint.title',
    icon: '/assets/icons/ic-digital-footprint.svg',
    description: 'global.dashCardInfos.digitalFootprint.description',
    url: '/dashboard/digitaler-nachlass',
    serviceKey: 'digital_footprint',
  },
  {
    title: 'global.dashCardInfos.cohabitationAgreement.title',
    icon: '/assets/icons/ic-marriage-contract.svg',
    description: 'global.dashCardInfos.cohabitationAgreement.description',
    url: '/dashboard/konkubinatsvertrag',
    serviceKey: 'cohabitation_agreement',
    progressive: true,
  },
  {
    title: 'global.dashCardInfos.successionPlanning.title',
    icon: '/assets/icons/ic-succession.svg',
    description: 'global.dashCardInfos.successionPlanning.description',
  },
];

export default serviceCards;
