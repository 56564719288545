import { Navigate } from 'react-router-dom';
import React from 'react';
import MainDashboard from 'src/components/pages/Dashboard';
import Page from '#/components/shared/ui/Page';
import useAuth from '#/hooks/useAuth';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import PADashboard from '#/components/pages/PartnerAdminDashboard/PADashboard';
import LoadingScreen from '#/components/shared/ui/LoadingScreen';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function Dashboard() {
  const { user } = useAuth();
  const { currentAccount, isSwitching } = useAccountSwitch();
  const [guestOn] = useLocalStorage('guest_on', null);

  const partnerRole = user?.partner?.role;

  if (partnerRole === 'owner' || partnerRole === 'admin') {
    return <PADashboard />;
  }
  if (partnerRole === 'consultant') {
    return <Navigate to="/dashboard/berater" />;
  }

  if (isSwitching && !currentAccount) {
    return <LoadingScreen />;
  }

  if ((!user?.primary_account && guestOn) || guestOn) {
    return <Navigate to={`/dashboard/gast-bei/${currentAccount?.id}`} />;
  }

  return (
    <Page title="Dashboard">
      <MainDashboard />
    </Page>
  );
}
