import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import useHighlightedText from '#/hooks/useHighlightedText';

const HighlightedTextFormat: FC<{
  context: string;
  targets: string[];
  onClick?: (word?: string) => void;
}> = ({ context, targets, onClick }) => {
  const highlightFormat = (word: string, key: number) => (
    <Box
      component="span"
      key={key}
      sx={{ color: 'primary.main', cursor: 'pointer' }}
      onClick={() => onClick && onClick(word)}
    >
      {word}
    </Box>
  );
  const highlightedText = useHighlightedText({ targets, highlightFormat });

  return <Typography>{highlightedText(context)}</Typography>;
};

export default HighlightedTextFormat;
