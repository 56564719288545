import { Box } from '@mui/material';
import DashboardContainer from '#/components/shared/dashboard-container';
import useLocales from '#/hooks/useLocales';
import AdvisorsHelpers from '#/components/pages/Dashboard/advisors-helpers';
import ServicesList from '#/components/pages/Dashboard/services-list';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function MainDashboard() {
  const { translate } = useLocales();
  const [showModal] = useLocalStorage('showModal', false);

  return (
    <DashboardContainer
      introTitle={String(translate('global.homeTitle'))}
      sx={{
        '& > *': {
          mb: 10,
        },
      }}
    >
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <AdvisorsHelpers initialModalOpen={showModal} />
      </Box>
      <ServicesList />
    </DashboardContainer>
  );
}
