import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useLocales from '#/hooks/useLocales';

export default function ClientOverviewButton() {
  const { translate } = useLocales();
  return (
    <Stack>
      <Link to="/dashboard/berater">
        <Button variant="contained">
          <Typography>
            {String(translate('global.consultantDashboard.title'))}
          </Typography>
        </Button>
      </Link>
    </Stack>
  );
}
