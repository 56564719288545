import { Box, Stack } from '@mui/material';
import React from 'react';
import ServiceActions from '#/components/pages/Dashboard/extras/service-actions';
import Iconify from '#/components/shared/ui/Iconify';
import useServiceStats from '#/hooks/useServiceStats';
import { BorderLinearProgress } from '#/components/shared/ui/BorderLinearProgress';

type Services =
  | 'living_will'
  | 'power_of_attorney'
  | 'last_will'
  | 'marriage_contract'
  | 'important_information';

type Props = {
  serviceKey: Services;
  extras: {
    toServiceURL: string;
    progress?: boolean;
  };
};

export default function CardExtras({ serviceKey, extras }: Props) {
  const stats = useServiceStats();

  const serviceStat = stats[serviceKey];

  const hasMarkedAsDone = serviceStat?.service?.marked_as_done;

  return (
    <Stack
      spacing={2}
      direction="column"
      justifyContent="flex-end"
      height={100}
    >
      {extras.progress && (
        <Stack direction="row" spacing={2} sx={{ flex: 1 }} alignItems="center">
          <Box sx={{ width: '100%' }}>
            <BorderLinearProgress
              variant="determinate"
              value={
                hasMarkedAsDone ? 100 : serviceStat?.service?.percentage || 0
              }
            />
          </Box>

          {(serviceStat?.service?.percentage === 100 || hasMarkedAsDone) && (
            <Iconify icon="fa-solid:check-circle" color="green" />
          )}
        </Stack>
      )}

      <ServiceActions
        extras={extras}
        isLoading={stats?.isLoading}
        serviceKey={serviceKey}
        {...serviceStat}
      />
    </Stack>
  );
}
