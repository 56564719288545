import useLocales from '#/hooks/useLocales';
import IntroHead from '#/components/shared/IntroHead';

export default function Intro() {
  const { translate } = useLocales();

  return (
    <IntroHead
      title={String(translate('adminDashboard.main.title'))}
      description={String(translate('adminDashboard.main.description'))}
    />
  );
}
