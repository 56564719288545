import { Box } from '@mui/material';
import AnalyticCard from '#/components/pages/PartnerAdminDashboard/sections/analytics/charts/analytic-card';
import { toRgba } from '#/utils/cssStyles';
import { useGetAdminDashboardStats } from '#/api/partnerQueries';
import { IAdminDashboardStats } from '#/types/partner';
import useAuth from '#/hooks/useAuth';

type Props = {
  chartColor: {
    primary_color: string | undefined;
    secondary_color: string | undefined;
  };
};

export default function Metrics({ chartColor }: Props) {
  const { user } = useAuth();
  const isAdminOrOwner =
    user?.partner?.role === 'owner' || user?.partner?.role === 'admin';

  const { data: adminDashboardStats } =
    useGetAdminDashboardStats(isAdminOrOwner);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        justifyContent: 'space-around',
        flexWrap: {
          xs: 'wrap',
          lg: 'nowrap',
        },
      }}
    >
      {dashboardStats(adminDashboardStats, chartColor?.primary_color).map(
        (data, index) => (
          <AnalyticCard
            key={index}
            title={data.title}
            value={data.value}
            color={data.color}
            percentage={
              data.title ===
                'adminDashboard.analytics.cards.conversion_rate_login' ||
              data.title ===
                'adminDashboard.analytics.cards.conversion_rate_pay'
            }
          />
        )
      )}
    </Box>
  );
}

const dashboardStats = (
  stats?: IAdminDashboardStats,
  primaryColor?: string
) => [
  {
    value: stats?.visitors,
    title: 'adminDashboard.analytics.cards.visitors',
    color: toRgba(primaryColor ?? '#3366FF', 0.2),
  },
  {
    value: stats?.conversion_rate_login,
    title: 'adminDashboard.analytics.cards.conversion_rate_login',
    color: toRgba(primaryColor ?? '#3366FF', 0.4),
  },
  {
    value: stats?.logins_created,
    title: 'adminDashboard.analytics.cards.loginsCreated',
    color: toRgba(primaryColor ?? '#3366FF', 0.55),
  },
  {
    value: stats?.conversion_rate_pay,
    title: 'adminDashboard.analytics.cards.conversion_rate_pay',
    color: toRgba(primaryColor ?? '#3366FF', 0.7),
  },
  {
    value: stats?.paying_customers,
    title: 'adminDashboard.analytics.cards.paying_customers',
    color: primaryColor ?? '#3366FF',
  },
];
