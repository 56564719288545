import { Container, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import Intro from '#/components/pages/PartnerAdminDashboard/sections/intro';
import Metrics from '#/components/pages/PartnerAdminDashboard/sections/analytics/metrics';
import Charts from '#/components/pages/PartnerAdminDashboard/sections/analytics/charts';
import useAuth from '#/hooks/useAuth';
import MainInfoModal from '#/components/shared/MainInfoModal';
import ClientOverviewButton from '#/components/pages/PartnerAdminDashboard/sections/client-overview-button';
import { useGetPartnerSettings } from '#/api/partnerQueries';

export default function PADashboard() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const isAdminLoggedIn =
    user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

  const { data: partnerSettings } = useGetPartnerSettings(isAdminLoggedIn);

  const handleOpenInfoModal = () => setOpen(true);
  const handleCloseInfoModal = () => setOpen(false);

  useEffect(() => {
    if (user?.first_name === '' && user?.last_name === '') {
      handleOpenInfoModal();
    }
  }, [user]);

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          rowGap: 4,
        }}
      >
        <Intro />
        <ClientOverviewButton />
        <Metrics
          chartColor={{
            primary_color: partnerSettings?.primary_color,
            secondary_color: partnerSettings?.secondary_color,
          }}
        />
        <Charts
          chartColor={{
            primary_color: partnerSettings?.primary_color,
            secondary_color: partnerSettings?.secondary_color,
          }}
        />
      </Box>
      <MainInfoModal open={open} onClose={handleCloseInfoModal} />
    </Container>
  );
}
